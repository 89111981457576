<template>
  <main>
    <div class="tw-block lg:tw-hidden">
      <NavbarMain :fixed="true" />
    </div>

    <div class="tw-grid-cols-2 tw-gap-10 lg:tw-grid">
      <ClientOnly>
        <div class="tw-h-screen tw-bg-white lg:tw-overflow-y-scroll">
          <div
            class="tw-grid tw-min-h-screen tw-content-between tw-space-y-5 tw-p-8"
          >
            <div class="tw-hidden lg:tw-block">
              <AppLogo type="dark" />
            </div>

            <div class="stable-alignment">
              <div class="tw-py-16">
                <slot />
              </div>
            </div>

            <AppLicense />
          </div>
        </div>
      </ClientOnly>
      <ClientOnly>
        <div class="tw-hidden tw-h-screen lg:tw-block">
          <AuthBackground />
        </div>
      </ClientOnly>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.auth-background {
  background: url("https://files.sproutly.africa/assets/images/auth/background.png")
    no-repeat;
  background-size: cover;
}
</style>
