<template>
  <div
    class="tw-h-screen tw-w-full tw-bg-warning-25 tw-p-16 tw-pt-20 xl:tw-p-20"
  >
    <div class="tw-grid tw-h-full tw-place-content-center tw-justify-center">
      <div class="tw-space-y-10">
        <p class="tw-text-orange-800">
          Revenue Tracking, Bill Payments, Paylink, Perks and Rewards, and more
        </p>

        <h3
          class="tw-text-md tw-text-3xl tw-font-semibold tw-text-gray-900 md:tw-text-4xl xl:tw-text-5xl"
        >
          Financing education like never before; Sproutly V2.0 is live.
        </h3>

        <div class="tw-rounded-lg">
          <NuxtImg
            src="https://files.sproutly.africa/assets/images/auth/press-release.png"
            alt="press-release"
            class="tw-h-full tw-w-full tw-rounded-lg tw-object-cover"
            loading="lazy"
            format="webp"
            preload
          />
        </div>

        <p class="tw-text-xl tw-font-medium tw-text-gray-900 tw-underline">
          <a
            href="https://blog.sproutly.africa/"
            target="_blank"
            class="tw-flex tw-items-center tw-space-x-2"
          >
            <span
              >Read more on our newest releases and updates on Our Blog</span
            >
            <span>
              <AppIcon name="arrow-up-right" />
            </span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
